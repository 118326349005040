<template>
<div>
 
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Update User</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="First Name*"
                  v-model="user.first_name"
                  :error-messages="fNameErrors"
                  required
                  @input="$v.user.first_name.$touch()"
                  @blur="$v.user.first_name.$touch()"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  label="Last Name"
                  v-model="user.last_name"
                  :error-messages="lNameErrors"
                  required
                  @input="$v.user.last_name.$touch()"
                  @blur="$v.user.last_name.$touch()"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  v-model="profile.region"
                  :items="regions"
                  :error-messages="regionErrors"
                  label="Region"
                  required
                  @change="regionchange"
                  @blur="$v.profile.region.$touch()"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  v-if="profile.region == 'Southern Region'"
                  v-model="profile.district"
                  value=""
                  :items="southern"
                  :error-messages="districtErrors"
                  label="District"
                  required
                  @change="$v.profile.district.$touch()"
                  @blur="$v.profile.district.$touch()"
                ></v-select>
                <v-select
                  v-else-if="profile.region == 'Central Region'"
                  v-model="profile.district"
                  value=""
                  :items="central"
                  :error-messages="districtErrors"
                  label="District"
                  required
                  @change="$v.profile.district.$touch()"
                  @blur="$v.profile.district.$touch()"
                ></v-select>
                <v-select
                  v-else-if="profile.region == 'Northern Region'"
                  v-model="profile.district"
                  :items="northern"
                  :error-messages="districtErrors"
                  label="District"
                  required
                  @change="$v.profile.district.$touch()"
                  @blur="$v.profile.district.$touch()"
                ></v-select>
              </v-col>
              <!--
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="profile.location"
                  :error-messages="locationErrors"
                  label="Location"
                  required
                  @input="$v.profile.location.$touch()"
                  @blur="$v.profile.location.$touch()"
                ></v-text-field>
              </v-col>
              -->
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="profile.contact_number"
                  prefix="+265"
                  :error-messages="phoneErrors"
                  label="Phone number"
                  required
                  @input="$v.profile.contact_number.$touch()"
                  @blur="$v.profile.contact_number.$touch()"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="user.email"
                  :error-messages="emailErrors"
                  label="Email / Username"
                  required
                  @input="$v.user.email.$touch()"
                  @blur="$v.user.email.$touch()"
                ></v-text-field>
              </v-col>
             
              <v-col cols="12" sm="6">
                <v-select
                  v-model="profile.gender"
                  :items="['Male', 'Female']"
                  :error-messages="genderErrors"
                  label="Gender"
                  required
                  @change="$v.profile.gender.$touch()"
                  @blur="$v.profile.gender.$touch()"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select v-if="profile.user_type=='Admin'"
                v-model="profile.user_type"
                  :items="['Student', 'Tutor', 'Admin']"
                  :error-messages="userTypeErrors"
                  label="User Type*"
                  required
                  @change="$v.profile.user_type.$touch()"
                  @blur="$v.profile.user_type.$touch()"
                ></v-select>
              </v-col>
              <!--

              
               <v-col cols="12" sm="6" md="6">
                <v-text-field
                 v-model="user.password"
                 :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                   @click:append="show1 = !show1"
                  :error-messages="passwordErrors"
                  label="Password*"
                  :type="show1 ? 'text' : 'password'"
                  required
                  @change="$v.user.password.$touch()"
                  @blur="$v.user.password.$touch()"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                v-model="user.confirm_password"
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                   @click:append="show2 = !show2"
                   :type="show2 ? 'text' : 'password'"
                  :error-messages="confirmPasswordErrors"
                  label="Confirm Password*"
                  required
                  @change="$v.user.confirm_password.$touch()"
                  @blur="$v.user.confirm_password.$touch()"
                  
                ></v-text-field>
              </v-col>
              -->
            </v-row>
          </v-container>
          
          <small class="text-red">{{errors}}</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close()"> Close </v-btn>
          <v-btn  type="submit"
          color="teal"
          dark
          :loading="buttonLoader"
           
           @click="createAccount()"> Update </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required, maxLength, email, sameAs, minLength, integer } from "vuelidate/lib/validators";
import DataService from "@/authentication/data.service";
export default {
  mixins: [validationMixin],
  props: ["dialog", "userId"],
   data:() =>({
     buttonLoader:false,
      errors:"",
      show1: false,
        show2: false,
       the_user:null, 
      regions: ["Central Region", "Southern Region", "Northern Region"],
       northern: [
        "Chitipa",
        "Karonga",
        "Likoma",
        "Mzimba",
        "Nkhata Bay",
        "Rumphi",
      ],
      central: [
        "Dedza",
        "Dowa",
        "Kasungu",
        "Lilongwe",
        "Nkhotakota",
        "Ntcheu",
        "Ntchisi",
        "Salima",
      ],
      southern: [
        "Balaka",
        "Blantyre",
        "Chikwawa",
        "Chiradzulu",
        "Machinga",
        "Mangochi",
        "Mulanje",
        "Mwanza",
        "Neno",
        "Nsanje",
        "Phalombe",
        "Thyolo",
        "Zomba",
      ],
      user: {
        username:null,
        email: null,
        first_name: null,
        last_name: null,
        password:null,
        confirm_password:null,
      },
      profile: {
        user:null,
        district: null,
        region: "Southern Region",
        user_type: null,
        gender: null,
        location:null,
       contact_number: null,
      },
    
  }),
  validations() {
    return{
      user: {
      email: { required, email },
      first_name: { required },
      last_name: { required },
      password: {required, minLength: minLength(6),},
      confirm_password: {required, sameAs:sameAs(function(){return this.user.password} )},
    },
    profile: {
      district: { required, maxLength: maxLength(20) },
      region: { required, maxLength: maxLength(32) },
      location: { required, maxLength: maxLength(50) },
      user_type: { required },
      gender: { required },
     contact_number: { required, integer, minLength: minLength(9), maxLength: maxLength(9) },
    },
    }
    
  },

  async created(){
     await DataService.getProfile(this.userId).then(
      (response) => {
        const the_user = response.data;
        console.log("this is for updating the user", this.the_user)
        this.user=  {
        username:the_user.user.username,
        email: the_user.user.email,
        first_name: the_user.user.first_name,
        last_name: the_user.user.last_name,
        password:null,
        confirm_password:null,
      },
      this.profile = {
        user:null,
        district: the_user.district,
        region: the_user.region,
        user_type: the_user.user_type,
        gender: the_user.gender,
        location:null,
       contact_number: the_user.contact_number,
      }
      },
      (error) => {
        const errors =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        if (errors["detail"]) {
          if (errors["detail"] === 'Not found.')
            this.$router.push('/404');
        }

      }
    );
  },
   methods: {

    
     updateUser(data){
       console.log(data)
       DataService.updateProfile(this.userId, data).then(
      (response) => {
        this.profile = response.data;
        this.$v.$reset();
        this.close()
        this.buttonLoader = false;
        console.log('success', this.profile)
        
      },
      (error) => {
        this.errors =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
          console.log("error: ",this.content)
          this.buttonLoader = false;
      }
    );
     },
     createAccount(){
       this.$v.$touch();
       if (
        !this.$v.user.first_name.$invalid &&
        !this.$v.user.last_name.$invalid &&
        !this.$v.user.email.$invalid &&
        
        //profile
        !this.$v.profile.district.$invalid &&
         //!this.$v.profile.location.$invalid &&
        !this.$v.profile.region.$invalid &&
        !this.$v.profile.user_type.$invalid &&
        !this.$v.profile.gender.$invalid &&
        !this.$v.profile.contact_number.$invalid
      ) {
        this.buttonLoader = true;
        this.user.username = this.user.email
        this.profile.user = this.user
        this.updateUser(this.profile)
        //console.log(this.profile)
        
      }
      
     },
     regionchange() {
      this.$v.profile.region.$touch();
      this.profile.district = "";
    },
    
    close() {
      this.$emit("close_dialog", false);
    },
  },
  computed: {
   
    //password Errors
    passwordErrors() {
      const errors = [];
      if (!this.$v.user.password.$dirty) return errors;
      !this.$v.user.password.required && errors.push("Item is required");
      !this.$v.user.password.minLength && errors.push("password should not be less than 6 characters");
      return errors;
    },
    //confirm password Errors
    confirmPasswordErrors() {
      const errors = [];
      if (!this.$v.user.confirm_password.$dirty) return errors;
      !this.$v.user.confirm_password.required && errors.push("Item is required");
      !this.$v.user.confirm_password.sameAs && errors.push("Passwords dont match");
      return errors;
    },

    //region errors
    regionErrors() {
      const errors = [];
      if (!this.$v.profile.region.$dirty) return errors;
      !this.$v.profile.region.required && errors.push("Item is required");
      return errors;
    },
    //location errors
    locationErrors() {
      const errors = [];
      if (!this.$v.profile.location.$dirty) return errors;
      !this.$v.profile.location.required && errors.push("Item is required");
      !this.$v.profile.location.maxLength &&
        errors.push("Name must be at most 50 characters long");
      return errors;
    },
    //firstname errors
    fNameErrors() {
      const errors = [];
      if (!this.$v.user.first_name.$dirty) return errors;
      !this.$v.user.first_name.required && errors.push("Item is required");
      return errors;
    },

    //email errors
    emailErrors() {
      const errors = [];
      if (!this.$v.user.email.$dirty) return errors;
      !this.$v.user.email.email && errors.push("Must be valid e-mail");
      !this.$v.user.email.required && errors.push("E-mail is required");
      return errors;
    },
    //phone errors
    phoneErrors() {
      const errors = [];
      if (!this.$v.profile.contact_number.$dirty) return errors;
      !this.$v.profile.contact_number.required &&
        errors.push("Phone number is required.");
      !this.$v.profile.contact_number.integer &&
        errors.push("Must contain only numbers");
      !this.$v.profile.contact_number.minLength &&
        errors.push("Phone input must be 9 digits long e.g +265-XXX-XXX-XXX");
      !this.$v.profile.contact_number.maxLength &&
        errors.push("Phone input must be 9 digits long e.g +265-XXX-XXX-XXX");
      return errors;
    },
    //district errors
    districtErrors() {
      const errors = [];
      if (!this.$v.profile.district.$dirty) return errors;
      !this.$v.profile.district.required && errors.push("Item is required");
      return errors;
    },
    //User type errors
    userTypeErrors() {
      const errors = [];
      if (!this.$v.profile.user_type.$dirty) return errors;
      !this.$v.profile.user_type.required && errors.push("Item is required");
      return errors;
    },
    //gender errors
    genderErrors() {
      const errors = [];
      if (!this.$v.profile.gender.$dirty) return errors;
      !this.$v.profile.gender.required && errors.push("Item is required");
      return errors;
    },
    //last name
    lNameErrors() {
      const errors = [];
      if (!this.$v.user.last_name.$dirty) return errors;
      !this.$v.user.last_name.required && errors.push("Item is required");
      return errors;
    },
  },
 
 
};
</script>
<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Application Details</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-list
          three-line
          subheader
        >
          <v-list-item>
            <v-list-item-content>
              <b>Do you have experience doing lessons online?</b>
              <p>{{tutorData.experience}}</p>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <b>Where do you leave?</b>
              <p><b>Region:</b> {{user.region}}</p>
              <p><b>District:</b> {{user.district}}</p>
             
            </v-list-item-content>
          </v-list-item>
           <v-list-item>
             <v-list-item-content>
               <b></b>
              <b>How do we contact you?</b>
              <p><b>Email:</b> {{user.user.email}}</p>
              <p><b>Phone Number:</b> {{user.contact_number}}</p>
            </v-list-item-content>
           </v-list-item>
             <v-list-item>
             <v-list-item-content>
               <b></b>
              <b>What are you interested in?</b>
              <p><b>Level:</b> {{tutorData.level}}</p>
              <p><b>Curriculum :</b> {{tutorData.curriculum}}</p>
               
            </v-list-item-content>
           </v-list-item>
            <v-list-item>
             <v-list-item-content>
               <b>Which subjects do you need tutoring for?</b>
              <ul>
                <li v-for="subject in subjects" :key="subject">{{subject}}</li>
              </ul>
              
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
             <v-list-item-content>
               <b>Is there anything else you would like to add?</b>
              <p>{{tutorData.message}}</p>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        
          </v-container>
         
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close()"> Close </v-btn>
          
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>

//import DataService from "@/authentication/data.service";
export default {
 
  props: ["dialog", "tutorData", "user", "subjects"],
   data:() =>({
      
  }),
 
   methods: {
    
    close() {
      this.$emit("close_dialog", false);
    },
  },

 
 
};
</script>
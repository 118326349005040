<template>
  <v-row justify="center" v-if="studentData">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Application Details</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-list
          three-line
          subheader
        >
          <v-list-item>
            <v-list-item-content>
              <b>What is Your relationship to the learner?</b>
             <p>{{studentData['relation']}}</p>
            </v-list-item-content>
          </v-list-item>
           <v-list-item>
            <v-list-item-content>
              <b>What kind of tutoring do you require?</b>
              <p>{{studentData['level']}}</p>
            </v-list-item-content>
          </v-list-item>
           <v-list-item>
             <v-list-item-content>
               <b>Which subjects do you need tutoring for?</b>
              <ul>
                <li v-for="subject in subjects" :key="subject">{{subject}}</li>
              </ul>
              
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
             <v-list-item-content>
               <b>When would you like to start?</b>
              <p>{{studentData.start_date}}</p>
            </v-list-item-content>
          </v-list-item>
           <v-list-item>
             <v-list-item-content>
               <b>Are you open to doing lessons online?</b>
              <p>{{studentData.deliverly}}</p>
            </v-list-item-content>
          </v-list-item>
         
          <v-list-item>
             <v-list-item-content>
               <b>Is there anything else you would like to add?</b>
              <p>{{studentData.message}}</p>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        
          </v-container>
         
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close()"> Close </v-btn>
          
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>

//import DataService from "@/authentication/data.service";
export default {
 
  props: ["dialog", "studentData", "subjects"],
   data:() =>({
      
  }),
 
   methods: {
    
    close() {
      this.$emit("close_dialog", false);
    },
  },

 
 
};
</script>
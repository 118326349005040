<template>
  <div class="bg-gray-100" v-if="ready">
    <!--Update User-->
    <UpdateUser :userId ="id" :dialog="updateDialog" @close_dialog="close_update_dialog" />
    <!--End Of Update User-->
    <!--Delete Prompt-->
    <v-row justify="center">
      <v-dialog v-model="successDialog" persistent max-width="290">
        <v-card>
          <v-card-title class="text-h5" justify="space-around">
            <v-icon x-large color="red">mdi-delete-alert</v-icon>
          </v-card-title>
          <v-card-text>
            <p class="text-h6"><b>Are you sure you want to delete this account?</b></p>

          </v-card-text>
          <v-card-actions>
            <v-btn color="red darken-1" text @click="deleteAccount()">
              Delete
            </v-btn>
            <v-btn color="green darken-1" text @click="successDialog = false">
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!--End Of Delete Prompt-->
    <ApllicationDetails v-if="user.user_type == 'Student'" :studentData="studentApplication"
      :subjects="applicationSubjects" :dialog="dialog" @close_dialog="close_dialog" />
    <TutorApllicationDetails v-if="user.user_type == 'Tutor'" :user="user" :subjects="tutorapplicationSubjects"
      :tutorData="applications" :dialog="dialog" @close_dialog="close_dialog" />
    <Sidebar />
    <div class="md:ml-72 p-3 bg-gray-100 ">
      <div class="flex justify-between md:justify-end space-x-2 items-center mt-12 mb-2 mx-auto">
      <button @click="open_update_dialog()" class="
          bg-primary
          hover:bg-blue-600
          focus:outline-none
          rounded-lg
          px-6
          py-2
          text-white
          font-semibold
          shadow
        ">
                Update User
              </button>
      
      <button @click="successDialog = true" class="
                  text-red-600
                  hover:bg-red-400
                  focus:outline-none
                  rounded-lg
                  px-6
                  py-2
                  my-2
                  hover:text-white
                  font-semibold
                  shadow
                ">
                Delete Account
              </button>
    </div>
      <div class="mx-auto my-5 p-5">
        <div class="md:flex no-wrap md:-mx-2">
          <!-- Left Side -->
          <div class="w-full md:w-3/12 md:mx-2">
            <!-- Profile Card -->
            <div v-scroll-reveal class="bg-white p-3 border-t-4 border-green-400">
              <div class="text-center my-2">
                <img class="h-16 w-16 rounded-full mx-auto"
                  src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
                  alt="" />
                <h1 class="text-gray-900 font-bold text-xl leading-8 my-1">
                  {{ user.user.first_name + " " + user.user.last_name }}
                </h1>
              </div>

              <ul class="
                  bg-gray-100
                  text-gray-600
                  hover:text-gray-700 hover:shadow
                  py-2
                  px-3
                  mt-3
                  divide-y
                  rounded
                  shadow-sm
                ">
                <li class="flex items-center py-3">
                  <span>Account Type</span>
                  <span class="ml-auto"><span class="
                        bg-green-500
                        py-1
                        px-2
                        rounded
                        text-primary text-sm
                      ">{{ user.user_type }}</span></span>
                </li>
                <li class="flex items-center py-3">
                  <span>Member since</span>
                  <span class="ml-auto">{{ getDate(user.created_at) }}</span>
                </li>
              </ul>
              
              
            </div>
            <!-- End of profile card -->
            <div class="my-4"></div>

            <div v-scroll-reveal class="bg-white p-3 hover:shadow">
              <div class="
                  flex
                  items-center
                  space-x-3
                  font-semibold
                  text-gray-900 text-xl
                  leading-8
                  pb-4
                ">
                <span class="text-green-500">
                  <svg class="h-5 fill-current" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                  </svg>
                </span>
                <span>Change password</span>
              </div>
              <v-text-field outlined v-model="password.password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show1 = !show1" :error-messages="passwordErrors" label="Password*"
                :type="show1 ? 'text' : 'password'" required></v-text-field>

              <v-text-field outlined v-model="password.confirm_password"
                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'" @click:append="show2 = !show2"
                :type="show2 ? 'text' : 'password'" :error-messages="confirmPasswordErrors" label="Confirm Password*"
                required></v-text-field>

              <ul class="pb-3" v-for="error in errors" :key="error[0]">
                <li><small class="text-red">{{ error[1][0] }}</small></li>
              </ul>
              <button @click="changePassword()" class="
                  bg-primary
                  hover:bg-blue-600
                  focus:outline-none
                  rounded-lg
                  px-6
                  py-2
                  text-white
                  font-semibold
                  shadow
                ">
                Change Password
              </button>



            </div>
          </div>
          <!-- Right Side -->
          <div v-scroll-reveal class="w-full md:w-9/12 mx-2 h-64">
            <!-- Profile tab -->
            <!-- About Section -->
            <div class="bg-white p-3 shadow-sm rounded-sm">
              <div class="
                  flex
                  items-center
                  space-x-2
                  font-semibold
                  text-gray-900
                  leading-8
                ">
                <span clas="text-green-500">
                  <svg class="h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                  </svg>
                </span>
                <span class="tracking-wide">About</span>
              </div>
              <div class="text-gray-700">
                <div class="grid md:grid-cols-2 text-sm">
                  <div class="grid grid-cols-2">
                    <div class="px-4 py-2 font-semibold">First Name</div>
                    <div class="px-4 py-2">{{ user.user.first_name }}</div>
                  </div>
                  <div class="grid grid-cols-2">
                    <div class="px-4 py-2 font-semibold">Last Name</div>
                    <div class="px-4 py-2">{{ user.user.last_name }}</div>
                  </div>
                  <div class="grid grid-cols-2">
                    <div class="px-4 py-2 font-semibold">Gender</div>
                    <div class="px-4 py-2">{{ user.gender }}</div>
                  </div>
                  <div class="grid grid-cols-2">
                    <div class="px-4 py-2 font-semibold">Contact No.</div>
                    <div class="px-4 py-2">{{ user.contact_number }}</div>
                  </div>
                  <div class="grid grid-cols-2">
                    <div class="px-4 py-2 font-semibold">District</div>
                    <div class="px-4 py-2">{{ user.district }}</div>
                  </div>
                  <div class="grid grid-cols-2">
                    <div class="px-4 py-2 font-semibold">Region</div>
                    <div class="px-4 py-2">{{ user.region }}</div>
                  </div>
                  <div class="grid grid-cols-2">
                    <div class="px-4 py-2 font-semibold">Email.</div>
                    <div class="px-4 py-2">
                      <a class="text-blue-800" :href="'mailto:' + user.user.email">{{ user.user.email }}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End of about section -->

            <div class="my-4"></div>

            <!-- Experience and education -->
            <div v-if="user.user_type == 'Student' || user.user_type == 'Tutor'"
              class="bg-white p-3 shadow-sm rounded-sm">
              <div class="grid grid-cols-2">
                <div>
                  <div class="
                      flex
                      items-center
                      space-x-2
                      font-semibold
                      text-gray-900
                      leading-8
                      mb-3
                    ">
                    <span clas="text-green-500">
                      <svg class="h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                      </svg>
                    </span>

                    <span class="tracking-wide">Applications</span>
                  </div>
                  <ul v-if="user.user_type == 'Student'" class="list-inside space-y-2">
                    <li v-for="application in applications" :key="application.key">
                      <div class="text-teal-600">
                        Applied On: {{ getDate(application.created_at) }}
                      </div>
                      <div class="text-yellow-fade text-xs">
                        <a @click="open_student_dialog(application.id)">view details</a>
                      </div>
                    </li>
                  </ul>
                  <ul v-if="user.user_type == 'Tutor' && applications != null" class="list-inside space-y-2">
                    <li>
                      <div class="text-teal-600">
                        Applied On: {{ getDate(applications.created_at) }}
                      </div>
                      <div class="text-yellow-fade text-xs">
                        <a @click="open_dialog()">view details</a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- End of Experience and education grid -->
            </div>
            <!-- End of profile tab -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UpdateUser from '@/components/admin/UpdateUser'
import { validationMixin } from "vuelidate";
import {
  required,
  sameAs,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import Sidebar from "@/components/admin/Sidebar";
import DataService from "@/authentication/data.service";
import ApllicationDetails from "@/components/admin/StudentApplication";
import TutorApllicationDetails from "@/components/admin/TutorApplication";
export default {

  props: ['dialog', 'id'],

  mixins: [validationMixin],
  components: {
    TutorApllicationDetails,
    Sidebar,
    ApllicationDetails,
    UpdateUser
  },
  validations() {
    return {
      password: {
        password: {
          required,
          minLength: minLength(8),
          maxLength: maxLength(20),
        },
        confirm_password: {
          required,
          sameAs: sameAs(function () {
            return this.password.password;
          }),
        },
      },
    };
  },
  setup() { },
  computed: {
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.password.$dirty) return errors;
      !this.$v.password.password.required && errors.push("Item is required");
      !this.$v.password.password.minLength &&
        errors.push("password should not be less than 8 characters");
      !this.$v.password.password.maxLength &&
        errors.push("password should not be more than 20 characters");
      return errors;
    },
    //confirm password Errors
    confirmPasswordErrors() {
      const errors = [];
      if (!this.$v.password.confirm_password.$dirty) return errors;
      !this.$v.password.confirm_password.required &&
        errors.push("Item is required");
      !this.$v.password.confirm_password.sameAs &&
        errors.push("Passwords dont match");
      return errors;
    },
  },
  data() {
    return {
      successDialog: false,
      updateDialog: false,
      ready: false,
      show1: false,
      show2: false,
      password: {
        confirm_password: null,
        password: null,
      },
      subjects: null,
      applicationSubjects: null,
      studentApplication: null,
      tutorApplication: null,
      tutorapplicationSubjects: null,
      applications: null,
      user: {
        user: {
          date_joined: "",
          email: "",
          first_name: "",
          last_name: "",
          username: "",
        },
        contact_number: "",
        created_at: "",
        district: "",
        gender: "",
        region: "",
      },

      errors: [],
    };
  },
  methods: {
    async deleteAccount() {
      await DataService.deleteUser(this.id).then(
        (response) => {
          console.log(response.data)
          alert('account was deleted successifully')

          this.$router.push('/admin');
        },
        (error) => {
          const errors =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
          alert('something went wrong')
          console.log(errors["detail"])
        }
      );
    },
    changePassword() {
      this.$v.$touch();
      if (
        !this.$v.password.password.$invalid &&
        !this.$v.password.confirm_password.$invalid
      ) {
        const data = {
          new_password1: this.password.password,
          new_password2: this.password.confirm_password,
        };
        DataService.changePassword(data).then(
          (response) => {
            this.user = response.data;

          },
          (error) => {
            const errors =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();

            for (const value of Object.entries(errors)) {
              this.errors.push(value)
              //console.log(`${key}: ${value}`);
            }
          }
        );
      }
    },

    open_student_dialog(id) {

      const result = this.applications.filter(
        (application) => application.id == id
      );
      this.studentApplication = result[0];

      let application_subjects = [];
      for (const subject_id of this.studentApplication.subject) {
        const subject = this.subjects.filter(
          (subject) => subject.id == subject_id
        );

        application_subjects.push(subject[0].name);

      }
      //console.log(this.studentApplication.subject)
      this.applicationSubjects = application_subjects;
      this.dialog = true;
    },
    close_dialog(status) {
      this.dialog = status;
    },
    close_update_dialog(status) {
      this.updateDialog = status
    },
    open_update_dialog() {
      this.updateDialog = true
    },
    open_dialog() {


      let application_subjects = [];
      for (const subject_id of this.applications.subject) {
        const subject = this.subjects.filter(
          (subject) => subject.id == subject_id
        );

        application_subjects.push(subject[0].name);

      }
      //console.log(this.studentApplication.subject)
      this.tutorapplicationSubjects = application_subjects;
      this.dialog = true;
    },
    getDate(date_string) {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const date = new Date(date_string);
      const today = new Date();
      if (
        today.getDate() == date.getDate() &&
        today.getMonth == date.getMonth() &&
        today.getFullYear == date.getFullYear()
      ) {
        return "Today";
      } else {
        return (
          date.getDate() +
          ", " +
          months[date.getMonth()] +
          ", " +
          date.getFullYear() +
          "."
        );
      }
    },
  },
  async mounted() {

    let id = parseInt(this.id)
    if (!Number.isInteger(id)) {
      this.$router.push('/404');
    }
    //Fetching authenticated user
    DataService.getSubjects().then(
      (response) => {
        this.subjects = response.data;

      },
      (error) => {
        this.content1 =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
      }
    );
    await DataService.getAuthUser().then(
      async (response) => {
        const user = response.data;
        await DataService.getProfile(user.pk).then(
          (response) => {
            const user = response.data;
            if (user.user_type != "Admin" && id != user.user.id) {
              //alert("am here")
              //console.log("id",)
              //this.$router.push('/404');
              this.$store.dispatch('auth/logout', this.user).then(
                () => {

                  this.$router.push('/auth/login');
                },
                error => {
                  const errors =
                    (error.response && error.response.data) ||
                    error.message ||
                    error.toString();

                  for (const value of Object.entries(errors)) {
                    this.errors.push(value)

                  }
                }
              );
            }
          },
          (error) => {
            this.errors =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
            //this.$router.push('/404');
            this.$store.dispatch('auth/logout', this.user).then(
              () => {

                this.$router.push('/auth/login');
              },
              error => {
                const errors =
                  (error.response && error.response.data) ||
                  error.message ||
                  error.toString();

                for (const value of Object.entries(errors)) {
                  this.errors.push(value)

                }
              }
            );

          }
        );

      },
      (error) => {
        this.content1 =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        this.$store.dispatch('auth/logout', this.user).then(
          () => {

            this.$router.push('/auth/login');
          },
          error => {
            const errors =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();

            for (const value of Object.entries(errors)) {
              this.errors.push(value)

            }
          }
        );

      }
    );
    await DataService.getProfile(id).then(
      (response) => {
        this.user = response.data;
        this.ready = true

      },
      (error) => {
        const errors =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        if (errors["detail"]) {
          if (errors["detail"] === 'Not found.')
            this.$router.push('/404');
        }

      }
    );

    if (this.user.user_type == "Tutor") {
      await DataService.getTutorApplications(id).then(
        (response) => {
          this.applications = response.data;
          console.log(this.applications)
        },
        (error) => {
          this.content1 =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    } else if (this.user.user_type == "Student") {
      await DataService.getStudentApplications(id).then(
        (response) => {
          this.applications = response.data;

        },
        (error) => {
          this.content1 =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
        }
      );
    }

  },
};
</script>